@use "../../styles/index.scss" as *;

.download {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
  border: 1px dotted $primary;
  padding: 10px;

  @media screen and (max-width: 950px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.containerNextCalibrationLed {
  padding: 10px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  img {
    width: 30px;
    width: 30px;
  }
}

.containerAllLed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  // box-shadow: $inner-shadow;
  gap: 20px;
}

.containerBubbleNumberDay {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffff;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  span,
  h3 {
    color: $primary;
    font-size: 10px;
  }
}

.cabname {
  width: 150px;
}

.nameAndAdd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formSendEmail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  border-radius: 10px;
  padding-bottom: 25px;
  z-index: 1055;
  input {
    height: 35px;
    min-width: 80px;
    max-width: 155px;
  }
}

//USER SELECT

.selectUser {
  width: 300px;
}


.containerSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

//MODAL
.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
  font-size: 14px;
  font-size: 18px;
  h2 {
    font-weight: 800;
  }

  span {
    color: $primary;
  }
}
