@use "../../styles/index.scss" as *;

.back_login {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: $primary;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 22px;
  &:hover {
    transform: scale(1.03);
  }
}

//RESET PASSWORD page

.logo {
  display: flex;
  justify-content: center;
  margin: 25px auto;
}
.loginPage {
  width: 200px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px auto;
  background-color: $primary;
  border-radius: 15px;
  color: #ffff;
  font-weight: 600;

  &:hover {
    transform: scale(1.1);
  }
}

.successMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9; /* Couleur de fond légère */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre */
  max-width: 500px;
  margin: 50px auto;
  color: #333; /* Couleur du texte */
}


// ERROR TOKEN
.error_token {
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translate(-50%);
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  img {
    align-self: center;
    width: 200px;
    height: 100px;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
}
