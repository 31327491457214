.container{
h1{

    color: black;

}


}
.cabine{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

}