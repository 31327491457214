@use "../../../styles/index.scss" as *;
.container {
  position: relative;
  box-shadow: $shadow2;
  background-color: $greyLight-2;
  display: flex;
  align-items: center;
  padding: 25px;
  padding-left: 20px;
  font-weight: 500;
  z-index: 5;
  color: $primary;

  h3 {
    color: $primary;
  }
  margin-bottom: 15px;

  @media screen and (max-width: 1300px) {
   // display: none;
  }
}

.login {
  position: absolute;
  left: 20px;

  @media screen and (max-width: 1300px) {
 font-size: 11px;
 left:10px;
  }
}

.chevronFilterLogin {
  position: absolute;
  left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  @media screen and (max-width: 1300px) {

    left:30px;
     }
}
.lastName {
  position: absolute;
  left: 215px;
  @media screen and (max-width: 1300px) {
     display: none;
   }
}
.chevronFilterLastName {
  position: absolute;
  left: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  cursor: pointer;
  @media screen and (max-width: 1300px) {
    display: none;
    
  }
}


.email {
  position: absolute;
  left: 410px;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.containerChevronFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}


.droits {
  position: absolute;
  left: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  
  @media screen and (max-width: 1300px) {
    left: 90px;

  svg{
    display: none;
  }
  }
  h3 {
    margin-right: 5px;
  }
}
.allCab {
  position: absolute;
  left: 590px;
  width: 125px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.numberCab {
  position: absolute;
  left: 765px;
  width: 90px;
  
  text-align: center;
  @media screen and (max-width: 1300px) {
    display: none;
  }

 
}

.delete {
  position: absolute;
  right: 145px;
  width: 50px;
  margin-left: 140px;
  margin-right: 5px;
 
}

.settings {
  text-align: center;
  margin-left: 55px;
  position: absolute;
  right: 35px;

  @media screen and (max-width: 1300px) {
   // display: none;
  }
}

.arrow {
  font-size: 16px;
  @media screen and (max-width: 1300px) {
    font-size: 12px;
   
          }
}
