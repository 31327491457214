@use "../../../styles/index.scss" as *;

.settingsFeatures {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
margin-bottom: 10px;
gap: 10px;
//background-color: rgb(240, 244, 248);
padding: 8px;
font-size: 10px;
//box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

span {
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  flex-direction: column;
  align-items: center;
}
}
.optional {
  width: 830px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  margin-top: 10px;
  gap: 8px;
  h3 {
    margin-top: 5px;
    color:$primary;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.name {
  display: flex;
  align-items: center;
  gap: 0px;
  @media screen and (max-width: 768px) {
    position: relative;
    width: 200px;
    padding: 3px;
    border-bottom: 1px dotted black;

    input {
      position: absolute;
      right: 0;
      padding: 3px;
    }
  }
}

.setLogging {
  margin-top: -20px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap:5px;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    span {
      margin-top: 25px;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}

//CHECKBOX

.containerCheckBox {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1300px) {
    position: initial;
    align-self: center;
    margin-top: 5px;
  }

  input[type="checkbox"] {
    transform: scale(1.5);
    filter: sepia(100%) brightness(80%) hue-rotate(170deg) saturate(70%)
      contrast(300%);

    &:hover {
      cursor: pointer;
    }
  }
}

.containerKillSwitchButton {
  position: absolute;
  right: 10px;
  top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    right: 25px;
    top: 220px;
  }
}

//MODAl KILL SWITCH

.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.adminName {
  strong {
    color: $primary;
  }
}

.avertissement {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-weight: 700;
  font-size: 12px;
 
  margin-bottom: 30px;
}
.containerInput {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin: 30px;
}

.optionalKill {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.kill {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 50px;
  font-weight: 700;
}
.containerImg {
  margin-top: 50px;
}
