@use "../../../styles/index.scss" as *;

.cardFlipContainer {
  perspective: 1000px;
  width: 250px;
  height: 250px;
}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  cursor: pointer;
}

.card.isFlipped {
  transform: rotateY(180deg);
}

.cardFace {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardFaceFront {
  background-color: #fff;
}

.cardFaceBack {
  background-color: #f8f8f8;
  transform: rotateY(180deg);
}

.cardImage {
  width: 100%;
  height: 80%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cardTitle {
  position: absolute;
  bottom: 70px;
  right: 10px;
  background-color: $primary;
  color: #fff;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  font-weight: 600;
}

.cardContent {
  padding: 20px;
  text-align: center;
}

.cardText {
  font-weight: bold;
  font-size: 16px;
  span {
    color: $primary;
  }
}

// Utilisez cette classe pour tout contenu spécifique que vous souhaitez afficher au dos de la carte
.backContent {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  span{
    color: $primary;
  }
  p {
    margin-bottom: 10px;
  }
}
