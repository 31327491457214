.containerSucess {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
border-radius: 10px;
width: auto;
  height: auto;
  color: #2b7515;
  background: #ecffd6;
  border: 1px solid #617c42;
}
