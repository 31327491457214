@use "../../../styles/index.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.containerSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
}

.refreshSvg {
  color: $primary;
  font-size: 35px;
  &:hover {
    cursor: pointer;
  }
}

.containerCodeLed {
  position: absolute;
  top: 60px;
  left: 150px;
  width: 150px;
  background-color: #ffff;
  border-radius: 20px;
  padding: 10px;
  display: flex;
 flex-direction: column;
  gap:10px;
  margin: 10px;
z-index:5;
  @media screen and (max-width: 950px) {
position: absolute;
left: 150px;
top: 60px;
  }

}

.colorCodeLed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

