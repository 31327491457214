.notification {
  transition: background ease-in-out 300ms;
  padding: 0.2rem 2rem 0.2rem 0.8rem;
  width: 22rem;
  @media screen and (max-width: 400px) {
    width: 18rem;
  }

  &:hover {
    background: lightgrey;
  }

  &_title {
    font-size: 0.9rem;
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 3px;
  }

  p {
    font-weight: 400;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  &_date {
    margin-top: var(--margin-xxs);
    font-size: 0.7rem;
    color: rgb(184, 184, 184);
    font-weight: 400;
  }

  &:last-child {
    margin-bottom: 4px;
  }
  a {
    font-size: large;
  }
}
