.welcome {
    background:#333;
    border-radius: 10px;
    padding: var(--padding-md) var(--padding-md);
    color: white;


    h1 {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: var(--margin-xxs);
        @media screen and (max-width: 600px) {
            font-size: 1.2rem;
        }

        svg {
            font-size: 2rem;
            flex-shrink: 0;
            @media screen and (max-width: 600px) {
                font-size: 1.5rem;
            }
        }
    }
}
