// Couleurs générales
@use "../../../styles/index.scss" as *;

$hoverColor: #333;
$backgroundColor: #f4f6f6;
$fontColor: #333;

// Mixin pour les boutons
@mixin buttonStyle {
  padding: 0.5em 1em;
  margin: 0 1px;

  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hoverColor;
  }
}

.container {
  position: relative;
  width: 1020px;
  padding: 25px;
  margin: 25px auto;
  border-radius: 3rem;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7, -0.2rem -0.2rem 1.8rem #ffffff;

  @media screen and (max-width: 768px) {
    width: 300px;
  }
}

//filtre et recherche des users
.containerSearchAndFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.selectGroup{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

#inputSearchUser {
  input {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.containerUser {
  margin: 10px auto;
  h2 {
    padding-bottom: 10px;
    margin-top: 2em;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      color: white;
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
      border-radius: 4px;
      margin: 0.5em 0;
      display: flex;

      align-items: center;
    }
  }
}

//GESTION D'une erreur eventuelle en base de donnée
// le group n'existe pas ou plus
.userItem {
  position: relative;

}

.userItem.error:hover::before {
  content: "User en erreur si vous venez de renommer le groupe = modifier le fichier tableau dans config.js ici ==> manageUser\manageUserGroup\config.js";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(12, 12, 12, 0.8); /* Rouge semi-transparent */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  //  pointer-events: none; /* Pour éviter que l'overlay bloque les éléments en dessous */
  z-index: 10;
}

//
.userData {
  display: flex;
  justify-content: center;
  align-items: center;

}

.userId {
  width: 70px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.userLogin {
  width: 180px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.userName {
  width: 180px;
}
.email {
  width: 20px;
  @media screen and (max-width: 768px) {
margin-left: 50px;
  }
}

.group {
  margin-left: 50px;
  margin-right: 60px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.restrictions {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.permissions {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.buttonGroup {
  display: flex;
  flex-direction: column; // les boutons sont empilés verticalement
  gap: 10px; // espace entre les boutons
}
.containerBtn {
  width: 200px;
}

.btnPrincipale {
  @include buttonStyle;
}

.containerPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 20px auto;
  button {
    @include buttonStyle;
    background-color: $primary;
  }
}
.numberPage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-weight: 500;
}

/* Styles pour un bouton vert */
.btnGreen {
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  outline: none; /* Retirer l'outline, mais gardez à l'esprit les considérations d'accessibilité */
  text-align: center;
  display: inline-block;
  margin: 5px;
  background-color: #ccffcb;
}

/* Styles pour un bouton rouge */
.btnRed {
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  outline: none; /* Retirer l'outline, mais gardez à l'esprit les considérations d'accessibilité */
  text-align: center;
  display: inline-block;
  margin: 5px;
  background-color: #ffe0e0;
  color: black;
  text-decoration: line-through;
}

/* Style pour le bouton restricted (si vous souhaitez garder cette classe) */
.restricted {
  border: 1px solid black; /* ou n'importe quel autre style */
}

// CHANGER NOM GROUP

.renameGroupContainer {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  gap: 15px;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

//MODAL AVERTISSEMENT CHANGEMENT DE GROUP

.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.contentAvertissement {
  width: 600px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;

  span {
    color: $primary;
    font-weight: 600;
  }
  strong {
    font-weight: 500;
  }
}

.btnUpdate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
