.infosRole{
    padding: 10px;
ul{
    display: flex;
 flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    list-style-type: circle
}

li{
    margin-top: 10px;
}

span{
    font-style: italic;
    margin-left: 2px;
}
}