@use "../../../styles/index.scss" as *;

.wrapperModal {
  position: absolute;
  background-color: black;
  opacity: 0.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.containerModal {
  position: absolute;
  background-color: #f8f9fa;
  width: 100%;
  height: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -80%);
  margin: auto;
  z-index: 1;
  h2:nth-child(1) {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
    color:$primary;
  }
  h2 {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 600;
  }

  svg {
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b3e5fc;
  margin-bottom: 5px;
  margin-left: 8px;
  // box-shadow: 0.3rem 0.3rem 0.6rem #c8d0e7, -0.2rem -0.2rem 0.5rem #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  width: 90%;
  h3 {
    margin-right: 3px;
  }

  input {
    cursor: pointer;
  }
}

.late {
  background-color: #bbd0ff;
}
.soon {
  background-color: #c8b6ff;
}
.email {
  background-color: #fee440;
}
