.containerTitleSound {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #b8c0ff;
  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: -10px;
  font-weight: 700;
  cursor: pointer;
  .iconDropDown {
    font-size: 35px;
    cursor: pointer;
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.titleSound {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
  span {
    width: 160px;
    @media screen and (max-width: 768px) {
      width: 100px;
    }
  }
}

.bigContainerSoundCard {
  position: relative;
  background-color: #b8c0ff;

  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 0 0 10px 10px;
}

.containerSoundCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ccd7e4;
  padding: 5px;
  margin: 10px 20px 0px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;

  select {
    width: 40%;
    margin-right: 25px;
  }
}
span {
  margin-top: 25px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  select {
    margin-top: 15px;
  }
}

.soundImage {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.soundCardName {
  width: 40%;
  margin-right: 25px;
  font-weight: 600;
  font-size: 12px;
  @media screen and (max-width: 768px) {
    width: 25%;
  }
}

.outpout {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 8px;
  h3 {
    font-size: 14px;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
}
.type {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  h3 {
    font-size: 16px;
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.containerBtn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.noCalib {
  color: red;

  font-size: 12px;
  width: 40%;
  margin-right: 25px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    width: 25%;
  }
}

.btnUpdateSoundCard {
  font-size: 40px;
  color: #ccffcb;
  cursor: pointer;
  position: absolute;
  right: 25px;

  @media screen and (max-width: 768px) {
    font-size: 25px;
    margin-top: 10px;
  }
}

.btnReload {
  display: flex;
  align-items: center;
  justify-content: center;
}
