@use "../../styles/index.scss" as *;

.wrapperUser {
  position: relative;
  width: 1000px;
  padding: 25px;
  margin: 30px auto;
  @include PrimaryCard;

  @media screen and (max-width: 1300px) {
    width: 300px;
    padding: 15px;

    //box-shadow: none;
    border-radius: 0;
  }
}

#inputSearchAudio {
  width: 255px;
}

.searchUser {
  margin-bottom: 30px;
  @media screen and (max-width: 1300px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.containerRange {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h4 {
    margin-bottom: 3px;
  }
}

.containerToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  h3 {
    margin-right: 25px;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #1e293b;

  h1 {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .title_icon {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 30px;

    svg {
      font-size: 1.5rem;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .infosInvite {
    display: none;
  }
}

.title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25px auto;
  color: #1e293b;

  @media screen and (max-width: 768px) {
  }

  .title_iconWaiting {
    display: flex;
    align-items: center;

    gap: 0.5rem;

    svg {
      font-size: 3rem;
    }
  }
}
