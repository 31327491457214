@use "../../../styles/index.scss" as *;


.login__submit {

    white-space: nowrap;
    padding: 7px 10px 7px 10px;

    outline: none;
    border: none;
    background:$primary;
    box-shadow: $shadow;
    color: $greyLight-1;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 20px;
    //transition: background 200ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    &:hover {
        color: $white;
    }
    &:active {
        box-shadow:inset .2rem .2rem 1rem $primary-dark, 
               inset -.2rem -.2rem 1rem $primary-light;
      }
}

svg {
    font-size: 1.2rem;
}

  

  