.presetCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-image: linear-gradient(to bottom, #f04643, #334667);
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  span {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
  }
}

.index {
  position: absolute;
  bottom: 5px;
  left: 6px;
  z-index: 25;
}

.delete {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 15px;
  color: #fff;
  z-index: 5;
  &:hover {
    color: black;
    cursor: pointer;
  }
}
.containerPresetCard {
  display: flex; /* Using flex layout */
  flex-wrap: wrap; /* Prevent flex items from wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  justify-content: center; /* Align items to the start of the container */
  align-items: center; /* Center items vertically */
  gap: 10px;
  height: 250px;
  overflow: hidden;
  max-height: calc(100vh - 85px);
  overflow-y: auto;
  margin-bottom: 70px;
  button {
    cursor: pointer;
  }
}

.containerDescription {
  width: 100px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  top: -100px;
  right: 0px;
  border-radius: 20px;

  span {
    color: black;
    position: absolute;
  }
}

.infos {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 5;
  color: #fff;
  &:hover {
    color: black;
  }
}
