@use "../../../styles/index.scss" as *;

.wrapperModal {
  position: absolute;
  background-color: black;
  opacity: 0.2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.containerModal {
  position: absolute;
  background-color: #f8f9fa;
  width: 200px;
  height: 200px;
  border-radius: 15px;
padding: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 1;

  svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.containerBtn {
  position: absolute;
  bottom: 10px;
  left: 35px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.containerInfos {
  margin: 60px auto;

  span {
    font-weight: 700;
    color: $primary;
  }
}

.containerReponse {
  position: absolute;
  bottom: 65px;
  left: 35px;
}
