.containerError{
    display: flex;
    align-items: center;
    justify-content: center;
width: auto;
height: auto;
text-align: center;
padding: 4px;
border-radius: 10px;
color: #ba3939;
background: #ffe0e0;
border: 1px solid #a33a3a;
}