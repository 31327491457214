
// PADDING AND MARGIN 


$mg-xl: 4rem;
$mg-lg: 3rem;
$mg-md: 2rem;
$mg-sm: 1rem;
$mg-xs: 0.8rem;
$mn-xxs: 0.5rem;
$pad-xl: 4rem;
$pad-lg: 3rem;
$pad-md: 2rem;
$pad-sm: 1rem;
$pad-xs: 0.8rem;
$pad-xxs: 0.5rem;