// Couleurs générales
@use "../../styles/index.scss" as *;

$hoverColor: #333;
$backgroundColor: #f4f6f6;
$fontColor: #333;

// Mixin pour les boutons
@mixin buttonStyle {
  padding: 0.5em 1em;
  margin: 0 1px;

  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hoverColor;
  }
}

.container {
  position: relative;
  width: 1020px;
  padding: 25px;
  margin: 25px auto;
  border-radius: 3rem;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7, -0.2rem -0.2rem 1.8rem #ffffff;

  h3 {
    font-size: 12px;
  }
}

//filtre et recherche des users
.containerSearchAndFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

#inputSearchUser {
  input {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.containerUser {
  margin: 10px auto;
  h2 {
    padding-bottom: 10px;
    margin-top: 2em;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      color: white;
      font-size: 14px;
      font-weight: 600;
      padding: 5px;
      padding-left: 15px;
      border-radius: 4px;
      margin: 0.5em 0;
      display: flex;

      align-items: center;
    }
  }
}

.containerInfosMicro {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.serial {
  color: #333;
  font-size: 12px;
}

.selectMicro {
  width: 80px;
}
#inputValueLeft {
  width: 100px;
  height: 32px;
  padding-left: 0;
  text-align: center;
}
#inputValueRight {
  width:100px;
  height: 32px;
  padding-left: 0;
  text-align: center;
}



.buttonGroup {
  display: flex;
  flex-direction: column; // les boutons sont empilés verticalement
  gap: 10px; // espace entre les boutons
}
.containerBtn {
  width: 200px;
}

.btnPrincipale {
  @include buttonStyle;
}

.containerPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 20px auto;
  button {
    @include buttonStyle;
    background-color: $primary;
  }
}
.numberPage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-weight: 500;
}

.iconAddNewMics {
  position: absolute;
  right: 75px;
}

//MODAL ADD NEW MICS

.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
  gap: 20px;

  select {
    width: 80%;
  }
  option {
    text-align: center;
  }
}
