@use "../../../styles/index.scss" as *;
.card {
  position: relative;
  margin-top: 10px;
  width: 220px;
  border-radius: 10px;
  transition: 0.5s;
  padding: 10px;
  h4 {
    font-weight: 500;
    // color: #fff;
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
width: 150px;

  }
}

.card .box {
  box-shadow: $shadow;
  border-radius: 15px;
  height: 140px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.card .box:hover {
  box-shadow: $inner-shadow;
  transform: scale(1.01);
}

.card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  //background: rgba(255, 255, 255, 0.03);
}

.card .box .content {
  padding: 15px;
  text-align: center;
}

.card .box .content h3 {
  font-size: 1rem;

  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  //color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  //color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
