@use "../../../styles/index.scss" as *;

.containerSelectUser {
  width: 220px;

  padding: 15px;
  gap: 20px;
  border-radius: 20px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  h2 {
    font-weight: 500;
    font-size: 1em;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  svg {
    font-size: 1.5em;
  }
}
.selectUser {
  width: 300px;
}

.containerSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

//MODAL
.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 50px;
  font-size: 14px;
  font-size: 18px;
  h2 {
    font-weight: 800;
  }

  span {
    color: $primary;
  }
}
