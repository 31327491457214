@use './colors' as *;


$boxShadow:rgba(0, 0, 0, 0.1) 0px 4px 12px;



$shadow: .3rem .3rem .6rem $greyLight-2, 
-.2rem -.2rem .5rem $white;

$shadowNav: .1rem .1rem .1rem $primary, 
-.1rem -.1rem .1rem $white;

$shadow2: .3rem .3rem .6rem $greyDark, 
-.2rem -.2rem .5rem $white;

$shadowYellow: .3rem .3rem .6rem $greyDark, 
-.2rem -.2rem .5rem $yellow;


$inner-shadow: inset .2rem .2rem .5rem $greyLight-2, 
inset -.2rem -.2rem .5rem $white;






   
  
  