@use "../../../styles/index.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: $headerCardColor;
  box-shadow: $shadow;
 padding: 25px;
  margin: 20px auto;
  color: $black-color;
  font-weight: 600;
  border-radius: 10px;
  //box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 20px 0px;

      }


}
