@use "../../styles/index.scss" as *;

#containerInputUser {
  text-align: center;
  input {
    width: 270px;

    &::placeholder {
      text-align: center;
    }
  }
}
#containerInputPassword {
  text-align: center;
  input {
    width: 270px;

    &::placeholder {
      text-align: center;
    }
  }
}
#btnConnexion {
  width: 150px;
}

.remember {
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: $greyDark;
  }

  label {
    font-size: 15px;
    font-weight: 500;
    margin-right: 3px;
  }
  input[type="checkbox"] {
    accent-color: #010e26;
    &:hover {
      cursor: pointer;
    }
  }
}

.forgot {
  color: $greyDark;
  text-decoration: underline;
  font-size: 18px;

  &:hover {
    font-weight: 700;
    cursor: pointer;
  }
}
