@use "../../../styles/index.scss" as *;


.img{
  width: 30px;
  width: 30px;
}

.title {
  margin: auto;
}

.container {
  margin-bottom: 100px;
  border-radius: 3rem;

  position: relative;
  margin: 30px auto;

  z-index: 2;
  button {
    margin: 15px auto;
  }
}

.containerAllLate {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;

  h2 {
    margin: 15px auto;
    font-weight: 600;
  }
}

.lateContainer,
.soonContainer,
.containerEmail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border: $primary 1px dotted;
  gap: 10px;
  border-radius: 20px;
  padding: 15px;
  h2 {
  }
}

.lateContainer {
  background-color: #bbd0ff;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7, -0.2rem -0.2rem 1.8rem #ffffff;
  min-width: 500px;
}

.soonContainer {
  background-color: #c8b6ff;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7, -0.2rem -0.2rem 1.8rem #ffffff;
  min-width: 500px;
}

.containerEmail {
  margin-top: 20px;
  background-color: #eeeeee;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7, -0.2rem -0.2rem 1.8rem #ffffff;
  margin-bottom: 50px;
  h2{
    font-weight: 600;
  }
}

.titleEmail {
  margin-top: 30px;
  margin-bottom: 30px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  transition: background-color 0.5s ease;
  cursor: pointer;
  // box-shadow: 0.3rem 0.3rem 0.6rem #c8d0e7, -0.2rem -0.2rem 0.5rem #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  width: 90%;
  h3 {
    margin-right: 3px;
  }

  input {
    cursor: pointer;
  }

  &:hover{

background-color: #b3e5fc;

  }
}

.cardEmailLate {
  background-color: #bbd0ff;
  h3 {
   
  }
}

.cardEmailSoon {
  background-color: #c8b6ff;
}

.cardChecked {
  background-color: #b3e5fc;
}

.allDateRelanceEmail{
  background: #ffffff;

  border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;


}