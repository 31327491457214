@use "../../styles/index.scss" as *;

.containerAccountOwner {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin: 100px 20px;
  border-radius: 15px;
 // padding: 30px;
  
  @media screen and (max-width: 768px) {
    width: 50%;
    justify-content: center;
    
margin: 0;
    padding: 0px;
    margin: 10px auto;
  }

  h1 {
    margin-bottom: 45px;
    color: $greyDark;
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
