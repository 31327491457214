@use "../../../styles/index.scss" as *;

/*  SEARCH  */
.search {
  display: flex;
  align-items: center;

  .searchInput {
    height: 2.2rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.1rem;
     box-shadow: $inner-shadow;
    background: none;
    font-family: inherit;
    color: $greyDark;
    text-align: center;
    padding-left: 35px;

    &::placeholder {
      color: $greyDark;
      text-align: center;
      font-size: 12px;
    }


  
    &:focus {
      outline: none;
      box-shadow: $shadow;
      text-align: left;

      + .search__icon {
        color: $primary;
      }
    }

    @media screen and (max-width: 768px) {
      background-color: #ffffff;
      box-shadow: none;
    }
  }

  &__icon {
    height: 2rem;
    position: absolute;
    font-size: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    color: $greyDark;
    transition: 0.3s ease;
  }
}
