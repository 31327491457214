@use '../../../styles' as *;


.grid{
display: flex;
align-items: center;
justify-content: center;
gap:45px;
padding: $pad-xs;
flex-wrap: wrap;
margin-bottom: $mg-md;


@media  screen and (max-width:768px) {

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap:10px;
      
        
        
          
}



}