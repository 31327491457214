@use "../../../styles/index.scss" as *;

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  font-size: 16px;
  span {
    color: $primary;
  }
}

.containerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.email{
  &:hover{
    color:$primary;
    transform: scale(1.2);
  }
}