@use "../../../styles/index.scss" as *;


.container {
    position: absolute;
    z-index: 5;
left: 70px;
top:50px;
background-color: #ffff;
width: 300px;
padding: 10px;
border-radius: 15px;
box-shadow: $boxShadow;
}