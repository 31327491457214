@use "../../../styles/index.scss" as *;

.containerForms {
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 15px;

  .screen {
    position: relative;
    height: auto;
    // width: 360px;
    box-shadow: $shadow;
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
  }

  .screenContent {
    z-index: 1;
    position: relative;
   // height: 100%;
    padding: 20px
    
    
    
  }
  @media screen and (max-width: 768px) {

    .screenContent {
padding: 20px 10px;

    }
  }
}
