.title {
  margin-bottom: 25px;
  font-weight: 600;

}


.labelEverySelect{
  font-weight: 700;
}


//IMPORT EXPORT
.containerBtnExport {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  button {
    color: aliceblue;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerImport {
  display: flex;

  gap: 20px;
  button {
    color: aliceblue;
  }
}

.containerInputImport {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.useCurrentDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.btnUpdate {
  position: absolute;
  margin-top: 60px;
  bottom: 10px;
  right: 10px;
 
}

.emptySelectedCard{
  display: flex;
margin: 10px auto;
justify-content: center;
align-items: center;
gap:5px;
font-weight:700;
font-size: 16px;

}