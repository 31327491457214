// Couleurs générales
@use "../../styles/index.scss" as *;

$hoverColor: #333;
$backgroundColor: #f4f6f6;
$fontColor: #333;

// Mixin pour les boutons
@mixin buttonStyle {
  padding: 0.5em 1em;
  margin: 0 1px;

  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hoverColor;
  }
}

.containerDiracLicence {
  padding: 25px;
  margin: 25px auto;
  border-radius: 20px;

  box-shadow: $boxShadow;
  margin-bottom: 100px;
}
.containersearchAndButton {
  display: flex;
  align-content: flex-start;
  gap: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  font-weight: 500;
}

.th {
  background-color: $primary;
  color: white;
  padding: 12px 8px;
  border-bottom: 1px solid #ddd;
}

.td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;

  &:nth-child(even) {
    // Style alternatif pour les lignes paires
    background-color: #f2f2f2;
  }
}

.input {
  padding: 6px;
  margin: 4px 0;
  box-sizing: border-box;
  width: 95%;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.containerBtn{
display: flex;

}


.button {
  padding: 6px 12px;
  margin: 4px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: $primary;
  color: white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005f73;
  }
}
.buttonCancel {
  padding: 6px 12px;
  margin: 4px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
background-color: #cc7171;
  color:white;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5e1e1e;
  }
}

.icon {
  margin-left: 10px;
  cursor: pointer;
  color: #008cba;

  &:hover {
    color: #005f73;
  }
}

.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 50px;
  input {
    width: 30px;
  }
}

.selectUser {
  width: 250px;
}
