
@use "../../../styles/index.scss" as *;

.containerInfosUser {
  background-color: #e2cfea;
  padding: 15px;
  margin: 10px 20px 25px 20px;
  h3 {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 7px;
  }
}


.containerInfosConnexion {
  background-color: #e5ffde;
  width: 280px;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; 
  h3 {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 7px;
  }
  
  @media screen and (max-width: 1024px) {
    
    width: 150px;
    
      .hideOnSmallScreen {
        display: none; /* Or block, depending on the element */
      
    }
    h3 {
      text-align: center;
      font-size:7px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 7px;
    }
  }

}

.accountUserConnexion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  font-size: 15px;
  font-weight: 500;
}
.owner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fefffe;
}

.containerInviteInfos {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
}

.invite {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 15px;

  .containerTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .email {
    color: rgb(109, 93, 252);
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  span {
    font-weight: 600;
  }
  button {
    margin-top: 10px;
  }
}

.titleInvite {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 30px;
    height: 25px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

.infosConnexion {
  position: absolute;
  top: 25px;
  left: 40%;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
}

.infosIcon {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.cabine {
  gap: 10px;
  font-weight: 700;

  height: auto;
  z-index: 12000000000000000000;
  strong {
    font-weight: 600;
  }
  span {
    font-weight: 100;
    font-style: italic;
    margin-left: 10px;
  }
}

.multiConnexion {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.noAccessCabine {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}
