@use "../../../styles/index.scss" as *;

//CONTAINER DES INFOS DE LA CONFIGURATION DU PC ET DE LA LICENCE DIRAC
.containerConfigPCAndDirac {
  width: 200px;
  height: 210px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

/* INFOS DE LA CONFIGURATION DU PC  */
.containerInfosDesktop {
  background-color: #fff3e0;
  box-shadow: $shadow;
  border-radius: 20px;
  padding: 5px;
  font-size: 12px;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: none;
  }
}

.infosComputer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  gap: 10px;
}

.fnameComputer {
  font-weight: 700;
  width: 100px;
}

/* INFOS DE LA LICENCE DIRAC */

.containerInfosDirac {
  background-color: #fce4ec;
  border-radius: 20px;
  padding: 15px;
  gap: 15px;
  font-size: 12px;
  display: flex;
  box-shadow: $shadow;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  h3 {
    font-weight: 800;
    font-size: 10px;

    span {
      color: $primary;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.infosDirac {
  gap: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.infos {
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  font-weight: 500;
}

//CONTAINER DES USERS ET DES NOTES SPECIFIQUES
.containerUsersAndSpecificNotes {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;

  @media (max-width: 1450px) {
    flex-direction: column; /* Change l'orientation en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    gap: 10px;
  }
}

/* UTILISATEURS DE LA CABINE */

.containerUsersUseCab {
  width: 210px;
  padding: 20px;
  margin-right: 5px;
  margin-left: -210px;
  margin-top: 10px;
  background-color: #ffe0b2;
  box-shadow: $shadow;
  border-radius: 10px;
  position: relative;
  z-index: 10; /* Default z-index for all items */
  transition: z-index 0.3s ease, box-shadow 0.3s ease;
  @media (max-width: 1450px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 70px;
  }
}

.userList {
  list-style-type: disc;

  margin-top: 10px;
}

.userListItem {
  margin-bottom: 10px;
  font-size: 10px;
  display: flex;
  align-items: space-between;
  gap: 10px;
  justify-content: flex-start;
}

.chevronContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.chevronIcon {
  font-size: 24px;
  color: $primary;
}

.chevronIcon:hover {
  color: #0056b3;
}
/* INFOS SPECIFICQUE A LA CABINES  */
.formArea {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%; // Make it flexible within the flex item
  gap: 10px;
  padding: 25px;
}
.containerText {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 870px;
  background-color: #e8f5e9;
  box-shadow: $shadow;
  text-decoration: none;
  padding: 15px;
  border-radius: 10px;
  white-space: pre-line;
  font-size: 14px;
  @media screen and (max-width: 768px) {
    width: 450px;
    max-width: none;
  }
}

textarea {
  height: auto;
  width: 100%;
  min-height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 10px;
  line-height: 1.5;
  z-index: 10;
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
}

.text {
  display: flex;
  align-items: center;
  gap: 10px;

  h3 {
    margin-left: 15px;
    font-size: 12px;
  }
}

.containerBtnValidation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
