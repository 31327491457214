@use "../../../../styles/index.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 50px;
    font-weight: 600;
  }

  a {
    margin-top: 15px;
    font-weight: 600;
    &:hover {
      color: $primary;
    }
  }
}
