@use "../../../styles/index.scss" as *;

.selectCab {
  width: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
 box-shadow: $shadow;
 border-radius: 10px;
  background-color: #FBFFFE;
  padding: 20px;

margin: 20px auto;
margin-bottom: 10px;
@media screen and (max-width: 768px) {
max-width: 320px;
}
  select {
    width: 320px;
    margin-right: 20px;
 
  }
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  input {
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    select {
      margin-right: 0;
    }
  }
}
