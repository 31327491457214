@use '../../../styles/index.scss' as *;


.bubbleInfos{

position: absolute;
background-color: #ffff;
border-radius: 25px;
padding: 10px;
top:-120px;
left:150px;
z-index: 25;
color: $primary;
font-size: 14px;
span{

   font-weight: 400;

}

}

