@use "../../styles/index.scss" as *;

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 60px;
  box-shadow: $shadow;
  padding: 20px;
  cursor: pointer;
  border-radius: 5px;

  svg {
    width: 25px;
    height: 25px;
  }

  @media screen and (max-width: 768px) {
    width: 70px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    border: 1px #6366f1 solid;
  }

  h3 {
    margin-top: 10px;
    text-align: center;
  }
}
