@use "../../styles/index.scss" as *;

.container {
  position: relative;
  box-shadow: $shadow2;
  background-color: $greyLight-2;
  display: flex;
  align-items: center;
  padding: 25px;
  padding-left: 20px;
  font-weight: 500;
  z-index: 5;
  color: $primary;

  h3 {
    color: $primary;
  }
  margin-bottom: 15px;
}

.userId {
  position: absolute;
  left: 10px;
}
.chevronFilterUserId {
  position: absolute;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  cursor: pointer;
}

.chevronFilterCabname {
  position: absolute;
  left: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}

.sub{
position: absolute;
left:85px;
}

.cabname {
  position: absolute;
  left: 280px;

  @media screen and (max-width: 1000px) {
    font-size: 10px;
    left: 65px;
  }
}

.chevronFilterCabname {
  position: absolute;
  left: 315px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    left: 95px;
  }
}

.newName {
  position: absolute;
  left: 500px;
  @media screen and (max-width: 1000px) {
    left: 195px;
    font-size: 10px;
  }
}

.save {
  position: absolute;
  right: 30px;
  @media screen and (max-width: 1000px) {
    right: 5px;
    font-size: 10px;
  }
}

.containerChevronFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.group {
  position: absolute;
  left: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1300px) {
    display: none;
  }
  h3 {
    margin-right: 5px;
  }
}

.arrow {
  @media screen and (max-width: 1000px) {
    font-size: 12px;
  }
}
.activeSort {
  color: green;
  font-size: 18px;
}
