@use "../../../styles/index.scss" as *;

.containerBtn {
  display: flex;
  gap: 5px;
  justify-content: center;
  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }
}

.containerInviteAndConfirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1300px) {
    margin: 0px auto;
  }
}

.userInvite {
  display: grid;
  grid-template-columns: 20% 20% 8% 8% 44%;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  overflow: hidden;
  margin: 10px 0;
  padding: 10px;

  color: #333;
  border-radius: 10px;
  box-shadow: $shadow;
  font-weight: 500;
  h3 {
    display: block;
    //width: 150px;
  }

  @media screen and (max-width: 1300px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 10px;
  background-color: rgba($color: #000000, $alpha: 0.6);
  border-radius: 10px;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;

  button {
    height: 35px;
  }
}

.title {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 25px auto;
  color: #1e293b;

  @media screen and (max-width: 768px) {
  }

  .title_icon {
    display: flex;
    align-items: center;

    gap: 0.5rem;

    svg {
      font-size: 3rem;
    }
  }
}

#inputSearchInvitation {
  width: 255px;
}

.success {
  position: absolute;
  top: 25px;
  right: 25px;
}
