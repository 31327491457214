@use "../../../styles/index.scss" as *;


.rules_password {

    padding: 8px;
  border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
font-size: 14px;

    color: $greyDark;


@media screen and (max-width:768px) {
  font-size: 10px;
}


  }
  .title_rules{
    font-weight: 700;
    text-decoration: underline;
    margin-bottom: 5px;
    @media screen and (max-width:768px) {
      font-size: 10px;
    }
  }


