.card {
  position: relative;
  min-width: 150px;
  min-height: 130px;
  border-radius: 10px;
  transition: 0.5s;
  padding: 15px;
  h4 {
    font-weight: 500;
    color: #fff;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    min-width: 100px;
padding: 5px;
  }
}

.card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  padding: 18px;

  img {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  

}



.card .box:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  transform: scale(1.01);
}

.card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.card .box .content {
  padding: 20px;
  text-align: center;
}

.card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.card .box .content h3 {
  font-size: 1.3rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;


  @media screen and (max-width: 768px) {
  
      font-size: 12px;
    
  }
}

.card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
