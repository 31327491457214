@use "../../../styles/index.scss" as *;

/*  SEARCH  */
.search {
  display: flex;
  align-items: center;

  &__input {
    height: 2.2rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.4rem;
    padding-left: 35px;
    box-shadow: $inner-shadow;
    background: none;
    font-family: inherit;
    color: $greyDark;
    text-align: center;

    &::placeholder {
      color: $greyDark;
     text-align: center;
      font-size: 14px;
    }
    &:focus {
      outline: none;
      box-shadow: $shadow;
      text-align: left;


      + .search__icon {
        color: $primary;
      }
    }
  }

  &__icon {
    height: 2rem;
    position: absolute;
    font-size: 2rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    color: $greyDark;
    transition: 0.3s ease;
  }
}

.lock {
  font-size: 2rem;
  height: 2rem;
  position: absolute;
  display: flex;
  align-items: center;
 right: 55px;
  color: $greyDark;
}

//MOBILE
@media screen and (max-width: 768px) {
  .search {
    &__input {
      background-color: #ffffff;
      box-shadow: none;
    }
  }
}
