

// FONTS COLORS
$background-dashboard: #e4ebf5;


$primary-light: #8abdff;
$primary: #6d5dfc;
$primary-dark: #5b0eeb;

$white: #FFFFFF;
$greyLight-1: #E4EBF5;
$greyLight-2: #c8d0e7;
$greyLight-3: #bec8e4;
$greyDark: #9baacf;


$headerCardColor:#c7ecee;
$yellow:#f6e58d;



$white-color: #edf2fb;
$black-color: #333;
$secondary-color: rgb(15, 23, 42);



body {
  background: $background-dashboard;
}


// // NAV COLORS
// $color_nav_bg: rgb(30, 41, 59);
// $color_nav_txt: rgb(100, 116, 139);
// $color_nav_ctg: rgb(226, 232, 240);
// $color_nav_active: rgb(85, 79, 232);
// $color_nav_not_active: rgb(61, 74, 94);

