@use "../../../styles/index.scss" as *;

.containerCab {

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
padding: 10px;
margin-bottom: 20px;
 font-weight: 700;
  
  .iconDropDown {
    font-size: 35px;
    cursor: pointer;
  }
  
  img {
    width: 40px;
    height: 40px;
  }
}

/* Media query pour modifier l'affichage à partir de 1250 pixels */
@media (max-width: 1250px) {
  .containerCab {
    flex-direction: column; /* Change l'orientation en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    justify-content: center;
    flex-wrap: nowrap;
    gap:10px;
  }
}


.iconDropDown {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 12;
}

.containerSettingsCab {
  width: 870px; // Set the container's width to 800px
margin: 0px 10px 0px 10px;
  box-shadow: $shadow;

  position: relative;
  background-color: #ceeaf7;
  padding: 15px;
   border-radius: 10px;

  h3 {
    font-weight: 700;
    font-size: 12px;
  }
  input {
    height: 20px;
  }

  select {
    width: 135px;
  }

  @media (max-width: 768px){
    width: 450px;
    margin-right: 10px auto;}
}

.cab {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.cabName {
  text-align: center;
}

.containerBtnDelete {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}
.containerBtnDeleteOpen {
  margin-bottom: 20px;
}
.cabNameTitle {
  color: $primary;
}

.linkToMachine {
  margin-top: 10px;
  font-weight: 400;
padding: 8px;
  font-size: 12px;
display: flex;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  h3 {
    font-size: 10px;
    font-weight: 700;
    color: #333;
  }
  span {
    font-size: 12px;
    color: $primary;
  }
}

.settingsFeatures {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  font-size: 10px;
  gap: 10px;

  span {
    margin-right: 5px;
  }
  h3 {
    font-size: 12px;
    color: $primary;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.optional {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    justify-content: center;
  }
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 768px) {
    position: relative;
    width: 200px;
    padding: 3px;
    border-bottom: 1px dotted black;

    input {
      position: absolute;
      right: 0;
      padding: 3px;
    }
  }
}
.containerMigration {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  gap: 30px;

  select {
    width: 200px;
    margin-right: 8px;
  }
}

.migration {
  display: flex;
  justify-content: flex-start;
  align-content: center;
}

.confCab {
  display: flex;
  justify-content: flex-start;
  align-content: center;

  width: 230px;
  gap: 5px;
  margin-top: 8px;
  border-radius: 20px;
  padding: 7px;
  cursor: pointer;
  background-color: #d5c9df;
  &:hover {
    transform: scale(1.05);
  }
}

.hiddenSettings {
  display: none;
}

//MODAL UNLINK CABINE
.wrapperModal {
  position: absolute;
  background-color: black;
  opacity: 0.2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.containerModal {
  position: absolute;
  background-color: #f8f9fa;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  padding: 15px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 1;

  svg {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  p {
    margin-top: 25px;
  }
  span {
    font-weight: 800;
  }
}
.containerBtn {
  position: absolute;
  bottom: 10px;
  left: 35px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

//CHECKBOX

.containerCheckBox {
  //position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1300px) {
  }

  input[type="checkbox"] {
    transform: scale(1.5);
    filter: sepia(100%) brightness(80%) hue-rotate(170deg) saturate(70%)
      contrast(300%);

    &:hover {
      cursor: pointer;
    }
  }
}
