.containerTitleMessageError {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ccd7e4;
    box-shadow: rgba(100, 100, 111, 0.2) 15px 7px 29px -15px;
  
    padding: 15px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 700;
  cursor: pointer;
    .iconDropDown {
      font-size: 35px;
      cursor: pointer;
    }
  
    img {
      width: 50px;
      height: 50px;
    }
  }
  
  .titleMessage {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
  }
  
  .containerSpeakers {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    flex-wrap: wrap;
    background-color: #ccd7e4;
    padding: 15px;
    margin: 0px 20px 0px 20px;
    //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 0 0px 10px 10;
  
    h2 {
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 30px;
    }
    h3 {
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 8px;
    }
    input {
      height: 15px;
    }
    input[type="number"] {
      width: 30px;
    }
    select {
      height: 35px;
      width: 250px;
    }
    options {
      height: 55px;
    }
  
    button {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }
  }

  .children{

    margin-left: 20px;
    margin-bottom: 50px;
  }