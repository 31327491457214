$form-border-color: #ddd;
$form-background-color: #f9f9f9;
$button-color: #007bff;
$button-hover-color: darken($button-color, 15%);

.formContainer {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid $form-border-color;
  border-radius: 8px;
  background-color: $form-background-color;
}

.formTitle {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.uploadForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileInput {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  width: calc(100% - 22px); // Ajustement pour padding et border
  border-radius: 4px;
}

.submitButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: $button-color;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $button-hover-color;
  }
}
