@use "../../../styles/index.scss" as *;

.container {
  background-color: #edf2fb;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-radius: 20px;
  padding-top: 30px;
  margin: 30px;
}

.containerSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 5px auto;
}

.metricsSelect {
  width: 250px;
}
