@use "../../../styles/index.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px auto;
  font-weight: 500;
  box-shadow: $shadow;
  padding: 45px;
  color: #333;
  border-radius: 15px;
  width: 975px;

  @media screen and (max-width: 1300px) {
    width: 75%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }

  h1 {
    margin-bottom: 30px;
  }
}

.containerTuto {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 55px;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
  }
}

.containerExplication {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  padding: 5px;
  text-align: center;
}

.guideInvite {
  width: 320px;
  position: absolute;
  z-index: 2;
  background-color: #ffff;
  top: 160px;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  box-shadow: $boxShadow;

  @media screen and (max-width: 1300px) {
    width: 250px;
  }
}

.containerSlider {
  border-radius: 15px;
  width: 440px;
  height: 440px;
  margin-bottom: 60px;

  @media screen and (max-width: 1300px) {
    margin-top: 230px;
  }
  @media screen and (max-width: 768px) {
    //  margin-top: 140px;
    width: 250px;
    height: 250px;
  }
}

.containerImg {
  img {
    width: 440px;
    height: 440px;
    border-radius: 15px;
    @media screen and (max-width: 768px) {
      width: 250px;
      height: 250px;
    }
  }
}

.guideInviteMail {
  width: 300px;
  height: auto;
}
