@use "../../styles/index.scss" as *;


.containerVideos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: $pad-xs;
  flex-wrap: wrap;
  margin-bottom: $mg-md;

 
}

.containerQuestions {
  margin-top: 80px;
}
.container {
  h1 {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
  }
}
.insertSvg{
  width: 27px;
  height: 27px;
}


.containerManual{
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  
}
.manual{
  
  display: flex;
  align-items: center;
  display: flex;
  flex-direction:column ;
  gap: 20px;

  span{
    font-weight: 500;
    font-size: 1em;
  }
}