@use '../../styles/index.scss' as *;


.btnPrimary {
    white-space: nowrap;
    padding: 5px 10px 5px 10px;
    outline: none;
    border: none;
    background:$primary;
    box-shadow: $shadow;
    color: $greyLight-1;
    font-size: 0.8rem;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    &:hover {
        color: $white;
    }
    &:active {
        box-shadow:inset .2rem .2rem 1rem $primary-dark, 
               inset -.2rem -.2rem 1rem $primary-light;
      }
}

svg {
    font-size: 0.8rem;
}

.btnValidation{
    white-space: nowrap;
    padding: 10px;
    outline: none;
    border: none;
    background:     #CCFFCB;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background 200ms ease-in-out;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    &:hover {
        background: #3DDC97;
    }
}

svg {
    font-size: 0.8rem;
}
    

.btnDelete{
    white-space: nowrap;
    padding: 10px;
    outline: none;
    border: none;
    background:   #ffe0e0;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background 200ms ease-in-out;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    &:hover {
        background:  #ba3939;
    }
}

svg {
    font-size: 0.8rem;
}
    
.btnExport{
    white-space: nowrap;
    padding: 10px;
    outline: none;
    border: none;
    background:   #006DAA;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background 200ms ease-in-out;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    &:hover {
        background:  #006DAA;
    }
}

svg {
    font-size: 0.8rem;
}
    

.btnImport{
    white-space: nowrap;
    padding: 10px;
    outline: none;
    border: none;
    background:   #0f0326;
    color: black;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background 200ms ease-in-out;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    &:hover {
        background:  #0f0326;
    }
}

svg {
    font-size: 0.8rem;
}
    


    



