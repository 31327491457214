@use "../../../styles/index.scss" as *;

.cardContainer {
  position: relative; // postition absolute dans container userSetting.scss
  text-align: center;
  box-shadow: $boxShadow;
  border-radius: 10px;
  margin-bottom: 10px;
  z-index: 2;
  @media screen and (max-width: 1300px) {
    // margin-left: -25px;
    //padding-left: 15px;
    // margin-right: 5px;
  }
}

.userInfos {
  display: flex;
  align-items: center;
  box-shadow: $shadow;
  padding: 20px;
  color: black;
  border-radius: 10px;

  h3 {
    font-size: 11px;
  }

  @media screen and (max-width: 1300px) {
    //  box-shadow: none;
    justify-content: space-between;
    padding: 20px 12px;

    h3 {
      font-size: 11px;
    }
  }
}

.cardSettingOpen {
  position: relative; // postition absolute dans container userSetting.scss
  text-align: center;
  -webkit-box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
  background-color: #f0f4f8;
  border-radius: 10px;
  margin-bottom: 15px;
  z-index: 2;

  @media screen and (max-width: 1300px) {
    // gap: 10px;
  }
}

.card_open {
  padding-bottom: 5px;
}

// align items with headers

.login {
  display: block;
  width: 9%;

  @media screen and (max-width: 1300px) {
    //  width: 100%;
    margin-right: 5px;
  }
}
.name {
  display: block;
  width: 25%;
margin-left: 20px;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.email {
  position: relative;
  cursor: pointer;
width: 12.5%;
  color: $primary;

  @media screen and (max-width: 1300px) {
    // width: 100%;
    display: none;
    //  width: 80%;
  }
}
.emailIcon {
  cursor: pointer;
  font-size: 25px;

  color: $primary;

  @media screen and (max-width: 1300px) {
    display: none;
    //  width: 80%;
  }
}
.showEmail {
  position: absolute;
  font-size: 15px;
  right: 0px;
  bottom: -30px;
  z-index: 25;
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 25px;

  height: 20px;
  @media screen and (max-width: 1300px) {
    // top: 10px;
    //  right: 100px;
  }
}

.formSelect {
  position: absolute;
  right: 415px;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
  width: 110px;

  @media screen and (max-width: 1300px) {
    right: 135px;
    width: 70px;
  }
}

.fakeSelect {
  display: flex;
  justify-content: center;
  background: $primary;
  color: white;
  padding: 8px;
  width: 110px;
  border: none;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  span {
    font-size: 14px;
  }

  @media screen and (max-width: 1300px) {
    width: 50px;
  }
}

.formSelect select {
  background-color: $primary;
  color: white;
  padding: 8px;
  width: 110px;
  border: none;
  font-size: 14px;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  @media screen and (max-width: 1300px) {
    width: 70px;
    margin-top: 0px;
  }
}

//CHECKBOX

.containerCheckBox {
  position: absolute;
  right: 335px;
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1300px) {
    right: 105px;
    //  width: 100%;
  }

  input[type="checkbox"] {
    transform: scale(1.5);
    filter: sepia(100%) brightness(80%) hue-rotate(170deg) saturate(70%)
      contrast(300%);

    &:hover {
      cursor: pointer;
    }
  }
}
.deleteUser {
  position: absolute;
  right: 5px;
  top: 22px;
  @media screen and (max-width: 1300px) {
    top: 16px;
  }
}

//SETTINGS OUVERT

.settings {
  position: absolute;
  right: 15px;
  width: 5rem;
  height: 3rem;
  border-radius: 20px;
  //box-shadow: $shadow;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: $primary;
  transition: all 0.5s ease;

  &:active {
    box-shadow: $inner-shadow;
    color: $greyLight-1;
  }
  &:hover {
    color: $primary;
  }
  @media screen and (max-width: 1300px) {
    // position: initial;
    //width: 100%;
  }
}

#inputSearchCabByCab {
  margin: 15px auto;
}

.allCab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
.numberCab {
  // width: 15%;
  position: absolute;
  right: 105px;
  @media screen and (max-width: 1300px) {
    display: none;
    // position: initial;
    // width: 80%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}

.role {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(30, 41, 59);
  color: #ffffff;
  padding: 15px;

  border-radius: 3px;
}
.infosBubble {
  cursor: pointer;
  height: auto;
  background-color: #6366f1;
}

.cabine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 3% 15px 3%;
  box-shadow: $boxShadow;

  padding: 1%;
  div &:first-child {
    margin-top: 40px;
  }

  @media screen and (max-width: 1300px) {
  }
}

.containerCheckboxCabByCab {
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  input[type="checkbox"] {
    transform: scale(1.3);
    filter: sepia(100%) brightness(80%) hue-rotate(170deg) saturate(70%)
      contrast(300%);

    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1300px) {
    //position: initial;
  }
}

.chevronBottom {
  margin-left: 5px;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.45em;

  &:before {
    top: 0;
    transform: rotate(135deg);
  }
}

.oneCabMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  span {
    margin-left: 5px;
  }
}
