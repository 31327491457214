.search{
display: flex;
align-items: center;
justify-content: center;
gap:15px;
margin-bottom: 50px;

input{
    height: 40px;
    width: 300px;
    
}

@media screen and (max-width:768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:15px;
    margin-bottom: 30px;
    
}

}