@use "../../styles/index.scss" as *;

.container {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 150px;

  h3 {
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }
  h4 {
    font-style: italic;
  }
}

.containerCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.contactCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 300px;
  height: 200px;
  box-shadow: $shadow;
  background-color: #eaf4f4;
  padding: 25px;

  border-radius: 5px;
  margin: 20px;
}

.email {
  background-color: #cce3de;
  box-shadow: $shadow;
}
.iconMail {
  &:hover {
    color: $primary;
  }
}
