@use '../abstracts/colors' as *;


@mixin box {

    border-radius: 50px;
    background: $background-dashboard;
    box-shadow:  -20px 20px 60px #cccfd3,
                 20px -20px 60px #ffffff;

}

