@use "../../styles/index.scss" as *;

.container {
  position: relative;
  box-shadow: $shadow2;
  background-color: $greyLight-2;
  display: flex;
  align-items: center;
  padding: 25px;
  padding-left: 20px;
  font-weight: 500;
  z-index: 5;
  color: $primary;

  h3 {
    color: $primary;
  }
  margin-bottom: 15px;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.serials {
  position: absolute;
  left: 20px;
}

.type {
  position: absolute;
  left: 235px;
}

.correctionL {
  position: absolute;
  left: 450px;
}

.correctionR {
  position: absolute;
  left: 715px;
}
.register {
  position: absolute;
  right: 10px;
}
