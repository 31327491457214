.container {
  a {
    margin-left: 15px;
    font-weight: 800;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
