.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    //background: url(../../pics/bg-connexion.jpg) no-repeat center center;
    // background: linear-gradient(90deg, #c7c5f4, #776bcc);
     background: linear-gradient(90deg,#010e26, #0f5cbf);
    //background: #F0F4F8;
  
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
gap:15px;
    h1{
 font-size: 1.8rem;
        color: white;
    }
    img{
        margin-right: 100px;
    }
}

.container{
margin-top: -200px;


}