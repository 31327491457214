.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:10
  }
  
  .loader {
    width: 200px;
    height: 200px;
    //border: 6px solid #ccc;
    //border-top: 6px solid #3498db;
    //border-radius: 50%;
    animation: bounce 1s linear infinite;
  }
  
  @keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0); }
  }