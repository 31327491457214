@use "../../../styles/index.scss" as *;

.containerTitleBone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e0aaff;
  cursor: pointer;
  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 700;

  .iconDropDown {
    font-size: 35px;
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
    
  }
}

.containerBtnExport {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  button {
    color: aliceblue;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerImport {
  display: flex;

  gap: 20px;
  button {
    color: aliceblue;
  }
}

.containerInputImport {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

//////////////////
.titleBone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  span {
    width: 160px;
    @media screen and (max-width: 768px) {
width: 100px;
    }
  }

}

.containerBoneCalib {
  position: relative;
  background-color: #e0aaff;
  padding: 15px;
  margin: 0px 20px 0px 20px;

  h2 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 22px;
  }
  h3 {
    font-size: larger;
    color: $primary;
    text-align: center;
    margin-bottom: 25px;
  }
  
  h4 {
    text-align: center;
   font-size: 15px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  input {
    height: 20px;
  }
  input[type="number"] {
    width: 50px;
    cursor: pointer;
  }
  select {
    height: 35px;
    width: 250px;
  }
  options {
    height: 55px;
  }

  span{
    font-weight: 500;
  }
}

.serial{
span{
  margin-top: 10px;
};

}
.containerValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    h3 {
      display: none;
    }
  }
}

.frequencies {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 5px;

  span {
    display: block;
    width: 58px;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 17px;
  }
}
.value {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerVocale {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}
.containerAllSerial {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.containerSerial {
  display: flex;
  gap: 30px;
  margin: 20px auto;
}
.serial{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.containerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 15px;
  }
}

.useCurrentDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.overlay {
  position: absolute;
  background-color: black;
  opacity: 0.3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
