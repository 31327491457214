@use "../../../styles/index.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  span {
    color: $primary;
  }
}
