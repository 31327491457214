@use "../../../../styles/index.scss" as *;

.modalConfirm {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  left: 50%;
  top: 15%;
  transform: translate(-50%, 10%);
  background-color: white;
  padding: 8px;
  width: 80%;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    h3 {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 10px;
    }
  }
}

.btnConfirmModal {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 10px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.useCurrentDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  label {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.closeBtnWarningOutpout {
  position: absolute;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
}
