@use "../../styles/index.scss" as *;

.containerQuestions {
  max-width: 50em;
  position: relative;
  margin: 0.5em auto;
 box-shadow: $shadow;
  margin-top: 30px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 3em;
    position: relative;
    background: $greyLight-2;
    
    transition: all 0.3s linear;
    &:hover {
      cursor: pointer;
      
      background: $greyDark;
    }
  }

  .content {
    background: #c3f7ea;
  }
  p {
    position: relative;
    padding: 1.2em 3em;
    margin: 0;
  }

  .containerList {
    margin-top: 15px;
  }

  li.link {
    text-decoration: underline;
    list-style: none;
    cursor: pointer;
    margin-top: 5px;

    &:hover {
      font-weight: 700;
    }
  }

  .answer {
    font-weight: 400;
    font-style: italic;
  }
}
