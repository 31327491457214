.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 50px;
  z-index: 5;
}

.dot {
  background-color: #333;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 2px;
  z-index: 5;
  //display: inline-block;
}

.dotOpen {
  background-color: #f0f4f8;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 2px;
  z-index: 5;
  //display: inline-block;
}

.container {
  background-color: white;
  position: relative; // postition relative sur le container Card dans userCard

  padding: 20px;
  right: 140px;
  margin-bottom: 10px;
  top: 8px;
  z-index: 3;
  width: 200px;
 display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: black;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  span {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
  }

  @media screen and (max-width: 1024px) {
    top: 225px;
    right: 30px;
  }

  span {
    margin-left: 3px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
}
.items {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 6px;
  }
}
