@use "../../../../styles/index.scss" as *;



.overlay {
    position: absolute;
    background-color: black;
    opacity: 0.3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  
  .modalConfirm {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    left: 50%;
    top: 15%;
    transform: translate(-50%, 10%);
    background-color: white;
    padding: 8px;
    width: 80%;
    border-radius: 20px;
  }

  .btnConfirmModal {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-top: 10px;
  }
  


  .useCurrentDate {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  
    input {
      &:hover {
        cursor: pointer;
      }
    }
  }
  
.closeBtnWarningOutpout{
    position: absolute;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
    }