@use "../../../styles/index.scss" as *;

.container {
  position: relative;
  box-shadow: $shadow2;
  background-color: $greyLight-2;
  display: flex;
  align-items: center;
  padding: 25px;
  padding-left: 20px;
  font-weight: 500;
  z-index: 5;
  color: $primary;

  h3 {
    color: $primary;
  }
  margin-bottom: 15px;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.userId {
  position: absolute;
  left: 10px;

}
.chevronFilterUserId {
  position: absolute;
  left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  cursor: pointer;
}
.login {
  position: absolute;
  left: 75px;
}

.chevronFilterLogin {
  position: absolute;
  left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}


.chevronFilterLastName {
  position: absolute;
  left: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  cursor: pointer;
}


.lastName {
  position: absolute;
  left: 260px;
}
.mdp {
  text-align: center;

  position: absolute;
  left: 405px;
}

.containerChevronFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.group {
  position: absolute;
  left: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 1300px) {
    display: none;
  }
  h3 {
    margin-right: 5px;
  }
}
.restriction {
  position: absolute;
  left: 670px;
  width: 125px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.permission {
  position: absolute;
 right: 25px;
  width: 125px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.numberCab {
  position: absolute;
  left: 765px;
  width: 90px;

  text-align: center;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.delete {
  position: absolute;
  right: 145px;
  width: 50px;
  margin-left: 140px;
  margin-right: 5px;
}

.settings {
  text-align: center;
  margin-left: 55px;
  position: absolute;
  right: 35px;

  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.arrow {
  font-size: 16px;
}
.activeSort{
  color:green;
  font-size: 18px;
}