@use "./colors" as *;

/* google FONTS POPPINS */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", "sans-serif";
  overflow: hidden;
  font-size: calc(1vmin + 6px);
  font-weight: 300;
}

p {
  font-size: 0.8em;
}



h1 {
  font-size: 1em;
  font-weight: 500;
}

h2 {
  font-size: 0.9em;
  font-weight: 400;
}
h3 {
  font-size: 0.7em;
  font-weight: 500;
  color: $black-color;
}
h4 {
  font-size: 0.6em;
}

h5 {
  font-size: 0.5em;
}

h6 {
  font-size: 0.4em;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.4em;
  }
  h2 {
    font-size: 1.2em;
  }
  h3 {
    font-size: 1.1em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.9em;
  }
}
