.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #6366f1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  z-index: 15;
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: ldsEllipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: ldsEllipsis3 0.6s infinite;
}
@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:10
}

.loader {
  width: 50px;
  height: 50px;
  //border: 6px solid #ccc;
  //border-top: 6px solid #3498db;
  //border-radius: 50%;
  animation: bounce 1s linear infinite;
}

@keyframes bounce {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}