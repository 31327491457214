@use '../../styles/index.scss' as * ;


.container{
display: flex;
align-items: center;
justify-content: center;
width: 50%;
margin: auto;
box-shadow: $inner-shadow;
border-radius: 20px;
padding: 30px;


}