@use "../../styles/index" as *;

.containerNotif {
  width: 33%;
  @include box;
  margin: 15px;
  padding: 20px;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.containerInput {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.notif {
  background: white;
  border-radius: 5px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.24);
  margin: 20px;
}
