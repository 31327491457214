.circle{
position: relative;
border: 2px solid black;
width: 150px;
height: 150px;    
border-radius: 50%;
margin-bottom: 15px;


@media  screen and (max-width:768px) {

    display: none;
    
}

img{
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width: 30px;
    height: 30px;
}

}

