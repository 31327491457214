//@use "./Submenus/Icons";
@use "../../styles/index.scss" as *;

.container {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 30px;
  border-bottom: 1px solid rgb(211, 211, 211);
  position: sticky;
  top: 0px;
  background: $greyLight-1;
  box-shadow: $shadowNav;
  z-index: 10;
  border-left: $greyLight-2 solid 2px;
height: 120px;
  @media screen and (max-width: 1024px) {
height:80px;
}
  svg {
    font-size: 1.2rem;
  }
}

.centralLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.burger_container {
  display: none;
  cursor: pointer;
  padding: 0.2rem;

  @media screen and (max-width: 1024px) {
    display: flex;
  }

  svg {
    font-size: 1.8rem;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 0.5rem;

  @media screen and (max-width: 360px) {
    justify-content: flex-start;
    margin-left: 1rem;
  }
}
