

  .container {
    margin-left: 20px;
    margin-right: 20px;



    button {
      border: none;
      
    }
  }
  
  .blocTabs {
    display: flex;
  }
  .tabs {
  
    padding: 15px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
    font-weight: 600;
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .activeTabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .activeTabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: #1e293b;
  }
  
  
  
 