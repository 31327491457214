.containerAllCard {
  display: flex;
justify-content: center;
  flex-wrap: wrap;
  margin-left: -10px;
  



  @media  screen and (max-width:768px) {
    
   display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px; 
}

}
