@use '../abstracts/colors' as * ;


@mixin PrimaryCard {
    border-radius: 3rem;
    box-shadow:.8rem .8rem 1.4rem $greyLight-2, 
    -.2rem -.2rem 1.8rem $white;



}
