
    .dropzone {
      width: 400px;
      height: 50px;
      border: 2px dashed #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: antiquewhite;
      cursor: pointer;
      
      @media screen and (max-width: 768px) {
          width: 300px;
      
        }


      }
      