@use "../../../styles/index.scss" as *;

.containerUser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%; // Ajustez en fonction des besoins spécifiques
  max-width: 300px; // Fixer une largeur pour éviter les déplacements
  position: relative; // Nécessaire pour positionner le select
  @media screen and (max-width: 1024px) {
    max-width: 200px; // Fixer une largeur pour éviter les déplacements
  }
}

.containerUser select {
  position: absolute; // Sort le select du flux normal pour qu'il n'influence pas les autres éléments
  top: calc(100% + 5px); // Positionne juste en dessous du champ de recherche
  left: 0;
  width: 100%;
  z-index: 25;

  @media screen and (max-width: 1024px) {
    position: initial;
  }
}

.containerUser input {
  width: 100%; // Fixe la largeur de l'input pour éviter les changements
  max-width: 300px; // Assure que l'input ne grandit pas au-delà de cette taille
}
