@use "../../styles/index.scss" as *;

.container {
  text-align: center;

  h1 {
    margin-top: 50px;
  }
}

.containerCard {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  @media screen and (max-width: 768px) {
    align-items: center;
    margin: auto;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.messageUser {
  width: 60%;
  margin: 50px auto;
  margin-top: 50px;
  background-color: #fff2b2;
  border-radius: 15px;
  padding: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.6;
  }
}
