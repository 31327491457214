@use '../../../styles/index.scss' as *;


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);
    
  }
  
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    display: flex;
    align-items: center;

  }

  .modal {
    z-index: 1000;
    background: $greyLight-1;
   // box-shadow: $shadow;
    position: relative;
    margin: auto;
    border-radius: 5px;
   // width: 400px;
 
    padding: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .closeBtn {
    position:absolute;
    right: 5px;
    top:5px;
    font-size: 1.4rem;
    font-weight: 700;
    color: #000;
    cursor: pointer;
    border: none;
    background: transparent;
  }