@use "../../../styles/index.scss" as *;

.containerTitlePreset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #a6b1e1;
  margin-top: 40px;

  cursor: pointer;
  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 700;

  .iconDropDown {
    font-size: 35px;
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
  }
}

.titlePreset {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  span {
    width: 160px;
  }
}

.containerPreset {
  position: relative;
  background-color: #a6b1e1;
  padding: 15px;
  margin: 0px 20px 0px 20px;
}

.containerPresetTest {
  position: relative;
  background-color: #edede9;
  padding: 15px;
  margin-top: 35px;
  border-radius: 20px;
}
.containerPresetCr {
  position: relative;
  background-color: #edf2f4;
  padding: 15px;
  margin-top: 25px;
  border-radius: 20px;
}

.overlay {
  position: absolute;
  background-color: black;
  opacity: 0.3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
