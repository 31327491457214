@use "../../../styles/index.scss" as *;

.overlay {
  position: absolute;
  background-color: black;
  opacity: 0.3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.containerTitleSpeakers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ccd7e4;
  box-shadow: rgba(100, 100, 111, 0.2) 15px 7px 29px -15px;

  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 700;
  cursor: pointer;
  .iconDropDown {
    font-size: 35px;
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
  }
}

.titleSpeakers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  span{

    width: 160px;
    @media screen and (max-width: 768px) {
      width: 100px;
  
    }
  }
}

.containerSpeakers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #ccd7e4;
  margin: 0px 20px 0px 20px;
  border-radius: 0 0px 10px 10;
  padding-bottom: 30px;
  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
  }
  label {
    font-size: 12px;
  }

  h2 {
    font-weight: 700;
    font-size: 15px;
  }
  h3 {
    font-weight: 700;
    font-size: 16px;
    color: $primary;
  }
  h4 {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 15px;
  }

  input[type="number"] {
    height: 20px;
    width: 50px;
  }
  select {
    height: 35px;
    width: 250px;
  }
  options {
    height: 55px;
  }
}




//ANGLE

.containerAllValueAngle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.containerValueAngle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  span {
    font-size: 14px;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerInputAngle {
  display: flex;

  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  @media screen and (max-width: 768px) {
    margin-bottom: 65px;
  }
}

.eachInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  h4 {
    display: block;
    width: 105px;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
  }
}

// EXPORT IMPORT
.containerBtnExport {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  button {
    color: aliceblue;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerInputImport {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.useCurrentDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.containerInputGeneral {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 8px;
  h4 {
    margin: 0;
  }
  @media screen and (max-width: 768px) {
  }
}

.containerValueTonal {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 3px;
  margin-top: -10px;

  span {
    width: 80px;
    font-weight: 600;
    margin-right: 8px;
    margin-top: 10px;
    font-size: 15px;
  }

  label {
    width: 55px;
    font-size: 14px;
  }
  input {
    width: 50px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerInputTonal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // margin-bottom: 20px;

  // height: 15px;
}

.blocVocal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 00px 70px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0 0 0 50px;
  }
}

.containerValueVocal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: -20px;
  span {
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
  }
  input {
    margin-right: 5px;
  }
  @media screen and (max-width: 768px) {
    span {
    }
  }
}

.containerAllSerial {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
}

.containerSerial {
  display: flex;

  gap: 15px;
  margin: 30px auto;
}
.serial{
  display: flex;
  align-items: center;
  flex-direction: column;
 
}
.containerBtnRegisterNewValue {
  display: flex;
  position: absolute;
  right: 15px;
  bottom: 15px;
  gap: 10px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    position: initial;
  }
}

.success {
  position: absolute;
  display: flex;

  bottom: 15px;
}
