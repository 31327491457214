
@use "../../styles/index.scss" as *;


.containerSuperAdmin{

  h2 {
    font-size: 1.1em;
    font-weight: 700;
    margin-top: 25px;
  }

}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between; // Ajuster selon les besoins
  gap: 20px; // Ajoute de l'espacement entre les éléments
  position: relative; // Pour permettre un positionnement absolu des enfants
  padding: 30px; // Ajoute de l'espacement autour du contenu

  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 15px;
    gap: 10px;
  }
}



.containerSettingsGeneralCab{
  cursor: pointer;
}
.containerAllCabinesFind {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.containerSettingsGeneralCab {
  background-color: #ceeaf7;
  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.containerSettingsCab {
  margin-bottom: 150px;
}





.withoutCab{
position: absolute;
right: 85px;
top:140px;

}