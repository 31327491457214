@use "../../../../styles/index.scss" as *;
.containerUser {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 5px;
  gap: 15px;

  select {
    //  margin-top: -30px;
    //  margin-bottom: 35px;
    margin-right: 10px;
    width: 450px;
    min-width: 125px;
  }
}

#containerInputSearch {

  input{
    color: #fff;
    text-align: left;


  }
  input::placeholder {
    color: #fff;
    text-align: left;

  }
}

#selectOpen {
  height: 200px;
}

@media screen and (max-width: 1275px) {
  .containerUser {
    select {
      width: 350px;
    }
  }
  @media screen and (max-width: 768px) {
    .containerUser {
      padding-top: 0px;
      select {
        width: 80%;
      }
    }
  }
}
