@use "../../../styles/index.scss" as *;

.infosAdress {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  z-index: 3;
  box-shadow:$shadow;
border-radius: 10px;
width: 250px;
height: 210px;
}

.containerModal {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.btnInfosAdress {
  button {
    @media screen and (max-width: 768px) {
      margin: 15px auto;
    }
  }
}

.btnUpdate {
  button{
    margin: 5px auto;
    white-space: nowrap;
    padding: 5px 10px 5px 10px;
    outline: none;
    border: none;
    background:$primary;
    box-shadow: $shadow;
    color: $greyLight-1;
    font-size: 0.6rem;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    &:hover {
        color: $white;
    }
    &:active {
        box-shadow:inset .2rem .2rem 1rem $primary-dark, 
               inset -.2rem -.2rem 1rem $primary-light;
      }

  }
}


.adress {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  font-size: 9px;
}

.containerLabel {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  font-weight: 500;
  label {
    font-size: 9px;
  }
}

.containerInput {
  margin-top: 5px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  input {
    font-size: 10px;
  }
}
