.todoApp {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 25px;

  width: 520px;
  min-height: 150px;
  background: #1e293b;
  text-align: center;
  // margin: 128px auto;
  border-radius: 10px;
  padding-bottom: 32px;
  h1 {
    margin: 32px 0;
    color: #fff;
    font-size: 24px;
  }
}


.complete {
  text-decoration: line-through;
  opacity: 0.4;
}

.todoForm {
  margin-bottom: 32px;
}

.todoInput {
  padding: 14px 32px 14px 16px;
  border-radius: 4px 0 0 4px;
  border: 2px solid #5d0cff;
  outline: none;
  width: 320px;
  background: transparent;
  color: #fff;
}

.todoInput::placeholder {
  color: #e2e2e2;
}

.todoButton {
  padding: 16px;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  outline: none;
  background: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgba(155, 0, 250, 1) 100%
  );
  color: #fff;
  text-transform: capitalize;
}

.todoIinput.edit {
  border: 2px solid #149fff;
}

.todoButton.edit {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
  padding: 16px 22px;
}

.todoContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}

.todoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px auto;
  color: #fff;
  background: linear-gradient(
    90deg,
    rgba(255, 118, 20, 1) 0%,
    rgba(255, 84, 17, 1) 100%
  );

  padding: 16px;
  border-radius: 5px;
  width: 90%;
}

.todoRow:nth-child(4n + 1) {
  background: linear-gradient(
    90deg,
    rgba(93, 12, 255, 1) 0%,
    rgba(155, 0, 250, 1) 100%
  );
}

.todoRow:nth-child(4n + 2) {
  background: linear-gradient(
    90deg,
    rgba(255, 12, 241, 1) 0%,
    rgba(250, 0, 135, 1) 100%
  );
}

.todoRow:nth-child(4n + 3) {
  background: linear-gradient(
    90deg,
    rgba(20, 159, 255, 1) 0%,
    rgba(17, 122, 255, 1) 100%
  );
}

.icons {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.deleteIcon {
  margin-right: 5px;
  color: #fff;
}

.editIcon {
  color: #fff;
}
