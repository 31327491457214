@use "../../styles/index.scss" as *;

.containerConnexion {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 80px auto;
  border-radius: 30px;
  background: #f0f4f8;
  box-shadow: -28px -28px 64px #cacdd0, 28px 28px 64px #ffffff;
  max-width: 1400px;

  @media screen and (max-width: 1100px) {
    background: none;
    box-shadow: none;
    margin: 20px auto;
    padding: 5px;
  }
}

.containerSignIn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  img {
    min-width: 220px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1100px) {
    position: absolute;
    left: 50%;
    top: 50%;
    
    transform: translate(-50%, -60%);
   
  }
}

.containerWelcomeLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../pics/bg-login.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #464646;
  background-position: center;
  height: 700px;
  width: 100%;
  border-radius: 30px 0 0px 30px;
  flex: 1;
  img {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1100px) {
  .containerWelcomeLogin {
    display: none;
 
  }
}
