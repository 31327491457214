@use "../../../styles/index.scss" as *;


.wrapper{
display: flex;
flex-direction: column
;
align-items: center;
justify-content: center;
line-height:1.8;
span{

    color:$primary;
    font-weight: 600;
    font-size: 16px
};
}