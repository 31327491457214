@use "../../../styles/index.scss" as *;
.container {
  // position: relative;
  display: grid;
  grid-template-columns: 20% 20% 8% 12% 40%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  text-align: center;
  box-shadow: $shadow2;
  background-color: $greyLight-2;
  padding: 20px;
  font-weight: 500;
  z-index: 5;
  color: $primary;

  h3 {
    color: $primary;
  }
  margin-bottom: 15px;

  @media screen and (max-width: 1300px) {
    display: flex;
  }
}

.containerChevronFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

.chevronFilterEmail {
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.email {
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.dateInvitation {
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.actions {
  margin-right: 10%;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.arrow {
  font-size: 16px;
  @media screen and (max-width: 1300px) {
    font-size: 12px;
  }
}

// .chevronFilterName {
//   position: absolute;
//   left: 90px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   margin-left: 10px;
//   @media screen and (max-width: 1300px) {
//     left: 30px;
//   }
// }

// .dateExpiration {
//   position: absolute;
//   left: 505px;
//   width: 90px;

//   text-align: center;
//   @media screen and (max-width: 1300px) {
//     display: none;
//   }
// }

// .chevronFilterExpiration {
//   position: absolute;
//   left: 555px;
//   width: 90px;

//   text-align: center;
//   @media screen and (max-width: 1300px) {
//     display: none;
//   }
// }

//   text-align: center;
//   @media screen and (max-width: 1300px) {
//     display: none;
//   }
// }
