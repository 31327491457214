@use './colors' as *;
@use './boxShadow' as *;

// RESET CSS

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  color: #000;
}

/*pour IE*/
html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  /* CUSTOM PROPERTIES */
  --margin-xl: 4rem;
  --margin-lg: 3rem;
  --margin-md: 2rem;
  --margin-sm: 1rem;
  --margin-xs: 0.8rem;
  --margin-xxs: 0.5rem;
  --padding-xl: 4rem;
  --padding-lg: 3rem;
  --padding-md: 2rem;
  --padding-sm: 1rem;
  --padding-xs: 0.8rem;
  --padding-xxs: 0.5rem;
}

.App {
  display: flex;
}

// select {
//   background-color: #333;
//   color: white;
//   padding: 8px;
//   border: none;
//   font-size: 14px;
//   box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
//   -webkit-appearance: button;
//   appearance: button;
//   outline: none;
//   border-radius: 8px;
//   cursor: pointer;
// }

select {
  background:$primary;
  color: white;
  padding: 8px;
  border: none;
  font-size: 14px;
  box-shadow:$boxShadow;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
}
