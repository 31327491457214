@use "../../../styles/index.scss" as *;

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 350px;
  strong {
    font-size: 12px;
    font-weight: 700;
  }
}

.cardImage {
  position: relative;
  max-height: 150px;
  width: 250px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card_price {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: $primary;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.card_content {
  padding: 20px;
}

.card_content::-webkit-scrollbar {
  width: 8px;
}

.card_content::-webkit-scrollbar-track {
  box-shadow: 0;
  border-radius: 0;
}

.card_content::-webkit-scrollbar-thumb {
  background: #c89b3f;
  border-radius: 15px;
}

.card_title {
  margin-bottom: 15px;
  text-align: center;
}

.card_text {
  overflow-y: auto; // Permet le défilement vertical du texte
  max-height: 200px; // Hauteur maximale du contenu textuel avant de défiler
  padding-right: 8px; // Ajustez selon vos besoins pour l'espace de la barre de défilement

  // Styles pour la barre de défilement (optionnel)
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.summaryList {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  // Si vous souhaitez utiliser <hr> au lieu de border pour séparer
  hr {
    border: none;
    height: 2px;
    background-color: $primary; // Couleur de la ligne de séparation
    margin: 10px 0; // Ajustez l'espacement autour de la ligne selon vos besoins
  }
}

.numbersTotal {
  color: $primary;
  font-size: 22px;
  font-weight: 600;
}
