.wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;


    h2{

margin-bottom: 20px;
margin-top: 20px;

    }
}