// Couleurs générales
@use "../../styles/index.scss" as *;

$hoverColor: #333;
$backgroundColor: #f4f6f6;
$fontColor: #333;

// Mixin pour les boutons
@mixin buttonStyle {
  padding: 0.5em 1em;
  margin: 0 1px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hoverColor;
  }
}

.container {
  position: relative;
  width: 800px;
  padding: 25px;
  margin: 25px auto;
  border-radius: 3rem;
  box-shadow: 0.8rem 0.8rem 1.4rem #c8d0e7, -0.2rem -0.2rem 1.8rem #ffffff;

  @media screen and (max-width: 1000px) {
    width: 360px;
    padding: 5px;
  }
}

//filtre et recherche des users
.containerSearchAndFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
}

.containerFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.refresh {
  @media screen and (max-width: 1000px) {
    margin-top: 10px;
  }
}
#inputSearchUser {
  input {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.containerUser {
  margin: 10px auto;
  h2 {
    padding-bottom: 10px;
    margin-top: 2em;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      color: white;
      font-size: 14px;
      font-weight: 600;
      padding: 10px;
      padding-left: 15px;
      border-radius: 4px;
      margin: 0.5em 0;
      display: flex;

      align-items: center;

      @media screen and (max-width: 1000px) {
        font-size: 11px;
      }
    }
  }
}

.containerInfosCabines {
  display: flex;
  justify-content: space-between; // Aligner les éléments horizontalement
  align-items: center; // Centrer les éléments verticalement

  .id {

    flex: 0 0 40px;
    color: #333;

    @media screen and (max-width: 1000px) {
      flex: 0 0 40px;
    }
  }

  .cabname {
    flex: 0 0 150px;
    color: #333;
    font-size: 11px;
    white-space: normal;
    overflow-wrap: break-word;
    @media screen and (max-width: 1000px) {
      flex: 0 0 100px;
    }
}

.sub {
    flex: 0 0 150px;
    color: #333;
    font-size: 11px;
    white-space: normal;
    overflow-wrap: break-word;
    @media screen and (max-width: 1000px) {
      flex: 0 0 100px;
    }
}

  #inputCab {
    flex: 0 0 160px;
    width: 100px;
    margin-right: 65px;
    @media screen and (max-width: 1000px) {
      width: 60px;
      height: 25px;
      flex: 0 0 60px;
      margin-right: 10px;
    }
  }
}

.btnMobile {
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
  }
}

.btnDesktop {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.containerPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 20px auto;
  button {
    @include buttonStyle;
    background-color: $primary;
  }
}

.numberPage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  font-weight: 500;
}
