@use '../../../styles/index.scss' as * ;



.switch {
 z-index: 100000; // attention important car permet que le cheked fonctionne
    & input:checked {
        box-shadow: $inner-shadow;
      & ~ label {
        box-shadow: $inner-shadow;
        svg {
          color: $primary;
        }
      }
    }
    input { display: none; }
  width: 4rem;
  display: flex;
  justify-content: center;
  label {
  box-shadow: $shadow;
      cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
      border-radius: .5rem;
      width: 1.8rem;
      height: 1.8rem;
    
  &:hover svg { color: $primary; }
  
  svg{
    font-size: 1.8rem;
    font-weight: 700;
    color: $greyDark;
    transition: .3s ease;
  }


}

}
