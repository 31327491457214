@use "../../styles/index.scss" as *;

//MODAL

.containerModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
  gap: 20px;
  
  form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

  }
  select {
    width: 80%;
  }
  option {
    text-align: center;
  }
}

.containerAllTypeEmail {
width: 1200px;
display: flex;
justify-content: center;
align-items: center;
margin: 70px auto;
flex-direction: column;

h2 {
  font-size: 16px;
  font-weight: 600;
}
}

.containerTypeEmail {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr; /* This will create three columns with different widths */
  gap: 15px; /* This will set space between columns */
  padding: 5px 15px;
align-items: center;
 margin:  20px;
  gap: 30px;
  box-shadow: $shadow;

}
#btnSendMail{
  display: block;
  justify-self: center;
  width: 250px;
padding: 10px;

text-align: center;
}
