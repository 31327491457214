@use "../../../styles/index.scss" as *;

.containerTitleBone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4fec1;
  cursor: pointer;
  padding: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 700;

  .iconDropDown {
    font-size: 35px;
    cursor: pointer;
  }

  img {
    width: 50px;
    height: 50px;
  }
}

.titleBone {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  span {
    width: 160px;
    @media screen and (max-width: 768px) {
      width: 100px;
    }
  }
}

.containerBoneCalib {
  position: relative;
  background-color: #f4fec1;
  padding: 15px;
  margin: 0px 20px 0px 20px;

  h2 {
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 22px;
  }

  span {
    font-weight: 500;
  }
  h3 {
    font-size: larger;
    color: $primary;
    text-align: center;
    margin-bottom: 25px;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 15px;
  }

  input {
    height: 20px;
  }
  input[type="number"] {
    width: 50px;
    cursor: pointer;
  }
  select {
    height: 35px;
    width: 250px;
  }
  options {
    height: 55px;
  }
}

.general {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;

  h4 {
    margin-top: 8px;
  }
}

.containerValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.direction {
  text-align: center;
  margin-top: 8px;
  width: 62px;
  margin-right: 8px;
}

.containerLeft {
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.containerRight {
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.frequencies {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 74px;

  span {
    display: block;
    width: 58px;
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 22px;
    margin-left: 0;
    gap: 18px;
  }
}
.value {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerVocale {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.containerAllSerial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.containerSerial {
  display: flex;
  gap: 25px;
  margin: 20px auto;
}
.serial {
  display: flex;
  align-items: center;
  flex-direction: column;
  span {
    margin-top: 5px;
    font-weight: 200;
  }
}

.vocaleTitle {
  text-align: center;
  margin-top: 35px;
}
.vocale {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  h4 {
    width: 60px;
    align-self: center;
    text-align: center;
    margin-top: 8px;
  }
}

.containerBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 8px;
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 15px;
  }
}

//IMPORT EXPORT
.containerBtnExport {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  button {
    color: aliceblue;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.containerImport {
  display: flex;

  gap: 20px;
  button {
    color: aliceblue;
  }
}

.containerInputImport {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.useCurrentDate {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.overlay {
  position: absolute;
  background-color: black;
  opacity: 0.3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
