@use "../../styles/index.scss" as *;

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: -10px;
  left: 0;
  background-color: #f0f4f8;
  z-index: 100000000000000000000;
  padding-top: 30px;
}

.logo {
  display: flex;
  margin: 25px auto;
}

.containerLoginAndCreate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    max-width: 500px;
    font-weight: 600;

    span {
      color: $primary;
    }
  }
}

@media screen and (max-width: 768px) {
.infos{
  max-width: 70%;
margin-left: 15px;
margin-right: 15px;

}
}

.containerBtn {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
  gap: 30px;
}

.createAccount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 500px;

  h2 {
    margin-bottom: 15px;
    margin: 0 30px 15px 30px;
    font-weight: 900;
  }
}
.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  margin-top: 30px;

  h2 {
    margin-bottom: 15px;
    font-weight: bold;
  }
  span {
    margin-top: 15px;
  }
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #e2e8f0;
  box-shadow: 5px 5px 10px #ced3da, -5px -5px 10px #f6fdff;
  margin-top: 100px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 30px;
  h2 {
    margin-bottom: 15px;
    font-weight: 700;
  }
  span {
    color: #6366f1;
  }
}
.loginHidden {
  display: none;
}

#inputUserAccount::placeholder {
  text-align: center;
}
#inputUserAccount {
//  text-align: center;
  width: 270px;
}

//CREATE ACCOUNT

#accountCreatePassWord {
  width: 220px;
  svg {
    display: none;
  }
}
#accountCreatePassWord2 {
  width: 220px;
}


.errorToken{
  display: flex;
  align-items: center;
  flex-direction: column;
max-width: 700px;
margin: auto;
gap:15px;
}