@use "../../../styles/index.scss" as *;

.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: $shadow;
  background: #c7ecee;
  border-radius: 10px;
  padding: 25px;
  color: $black-color;
  width: 50%;
  margin: 20px auto;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 5px;
  }

  h1 {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: var(--margin-xxs);
    @media screen and (max-width: 600px) {
      font-size: 1.2rem;
    }

    svg {
      font-size: 2rem;
      flex-shrink: 0;
      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }
  }
}
