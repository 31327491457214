@use "../../../styles/index.scss" as *;

.formInvite {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  border-radius: 10px;
  padding: 25px 0px 25px 0px;

  input {
    height: 35px;
    width: 350px;
  }

  @media screen and (max-width: 768px) {
    padding: 0px 5px 25px 5px;
    input {
      height: 35px;
      width: 250px;
    }
  }
}

.containerCheckOwnerMail {
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    height: 15px;
    width: 30px;
  }
  label {
    font-size: 12px;
    font-weight: 600;
    span {
      color: $primary;
    }
  }
}
