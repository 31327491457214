@use "../../../styles/index.scss" as *;


.confirmDelete {
  display: flex;

  align-items: center;
  padding: 35px 10px;
  button {
    margin-right: 15px;
  }

  h2 {
    margin-right: 15px;
  }
  span {
    color: #6366f1;
  }
}

