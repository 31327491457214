@use "../../styles/index.scss" as *;

.containerPageCalibration {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  // height: 100vh;
}

.umik {
  position: absolute;
  left: 35px;
  top: 150px;
  width: 250px;
  height: 250px;
  display: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.ears {
  position: absolute;
  right: 35px;
  top: 150px;
  width: 250px;
  height: 250px;
  display: none;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-size: 36px;
  font-weight: 600;
  h2 {
    margin-right: 15px;
  }
  img {
    width: 120px;
    height: 80px;
  }
}

.containerCalibration {
  @include PrimaryCard;
  position: relative;
  margin: 0px 0 30px 20px;

  padding: 35px;
  z-index: 2;
  img {
    z-index: 2;
  }

  @media screen and (max-width: 768px) {
    margin: 0px 20px 30px 20px;
    padding: 25px;
  }
}
.containerSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}
.refreshSvg {
  color: $primary;
  font-size: 35px;
  &:hover {
    cursor: pointer;
  }
}
.download {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 45px;
  margin-bottom: 25px;
}
.opacity {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
  background: white;
  // padding: 25px;
  opacity: 0.9;
}

.readyToDownload {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-content: center;
  justify-content: center;
  width: 80%;
  box-shadow: 5px 5px 15px -1px rgba(0, 0, 0, 0.22);
  background: white;
  padding: 55px;
  border: 3px rgb(30, 41, 59) solid;
  opacity: 1;
  animation: readyToDownload 1s ease-in-out;
  h2 {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 768px) {
    width: 60%;
    top: 30%;
    padding: 35px;
  }
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 25px;
  cursor: pointer;
}

@keyframes readyToDownload {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

h2 {
  text-align: center;
}

.earsImg,
.umikImg {
  position: absolute;
  width: 150px;
  height: 150px;
}

.umikImg {
  left: 50px;
  top: 100px;
}
.earsImg {
  bottom: 50px;
  right: 100px;
}

.oval {
  width: 50px;
  height: 38px;
  border-radius: 50%;
  background: #333;

  img {
    clip-path: ellipse(33% 50%);
  }
}

//ADVICE COMPONENT

.containerAdvice {
  //@include PrimaryCard;
  height: auto;
  margin-right: 20px;
  margin-left: 30px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding: 35px;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  h1 {
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 5px;
    font-style: italic;
  }

  svg {
    color: $primary;
    font-size: 25px;
    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 0px 20px 30px 20px;
  }
}

.containerCardAdvice {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 10px;

  @media screen and (max-width: 768px) {
    margin: 0px;
  }
}

.titleAdvice {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
