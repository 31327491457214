@use "../../styles/index.scss" as *;

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  svg {
    color: $primary;
    font-size: 40px;
  }
}

.container .card {
  position: relative;
  cursor: pointer;
}

.container .card .face {
  width: 200px;
  height: 130px;
  transition: 0.5s;
}

.container .card .face.face1 {
  position: relative;
  box-shadow: $shadow;
  background-color: $greyLight-2;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
 // transform: translateY(100px);
}

// .container .card:hover .face.face1 {
//   transform: translateY(0);
// }

.container .card .face.face1 .content {
  opacity: 0.7;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container .card:hover .face.face1 .content {
  opacity: 1;
}

.container .card .face.face1 .content img {
  max-width: 100px;
}

.container .card .face.face1 .content h3 {
  margin: 10px 0 0;

  color: $primary;
  text-align: center;
  font-size: 1.5em;
}

.container .card .face.face2 {
  position: relative;
  box-shadow: $shadow;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

 // transform: translateY(-100px);
}

.container .card:hover .face.face2 {
  transform: translateY(0);
}

.container .card .face.face2 .content p {
  color: $greyDark;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.container .card .face.face2 .content span {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  box-shadow: $shadow;
  padding: 15px;
}

.container .card .face.face2 .content span:hover {
  background: $primary;
  color: $greyLight-1;
}
