// Statistics.module.scss
@use "../../styles/index.scss" as *;

.containerFilterAndSummary {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px; /* Ajustez selon la largeur désirée */
  width: 100%;
  h2 {
    font-size: larger;
    font-weight: 700;
    font-family: "roboto";
    margin-bottom: 15px;
  }
}

.usersNumberStats {
  /* Ordre 3 sur les écrans larges */
  order: 1;
}

.summaryOfFilters {
  /* Ordre 1 sur les écrans larges, apparaît en premier */
  order: 3;
  flex: 1;
  min-width: 250px;
}

.containerFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: $shadow;
  min-height: 200px;
  order: 2;
  flex: 1;
  min-width: 900px;
}

@media (max-width: 1400px) {
  .containerFilterAndSummary {
    flex-direction: row; /* Change en disposition en ligne pour les grands écrans */
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne suivante si nécessaire */
    justify-content: center; /* Répartit l'espace entre les éléments */
    width: 100%;
  }

  .usersNumberStats,
  .summaryOfFilters {
    order: 1; /* S'assure que ces éléments apparaissent en premier */
flex: 1;
 display: inline-flex; /* Centrer horizontalement */
justify-content: center;
  }

  .containerFilter {
    width: 100%; /* Prend toute la largeur sous les deux autres éléments */
    order: 2; /* Place ce conteneur en dessous des deux autres */
  }
}

.selectors {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #43a047;
  }
}

.results {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 20px;
}

.resultItem {
  margin: 10px 0;
  padding: 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
}

.error {
  color: #d32f2f;
  margin: 20px 0;
}

.containerMainStats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: auto;

  padding: 20px;
}

.containerSelect {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.containerComparison {
  margin-top: 25px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerRadioComparaison {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
